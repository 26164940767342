<template>
  <!--static-->
  <b-modal
    :id="PREFIX + '-modal'"
    hide-footer
    @hide="resetForm()"
    @shown="initData()"
  >
    <template #modal-title>
      {{ action == 'editing'?$t('label_edit_note'):$t('label_add_note') }}
    </template>
    <template #default="{ hide }">


      <validation-observer
        #default="{handleSubmit, reset, invalid}"
        :ref="PREFIX + '_FORM'"
        tag="form"
        class="p-1"
      >

        <validation-provider
          #default="validationProps"
          :name="$t('label_subject')"
          rules="required"
          slim
        >
          <b-form-group
            :label="$t('label_subject')"
          >
            <v-select
                class="form-control"
                v-model="itemData.title"
                :options="[
                'Braki dokumentów',
                'PBUK',
                'Zgłoszenie',
                'Potwierdzenie odpowiedzialności',
                'Decyzja',
                'WPŁYW',
                'WPŁYW-dopłata',
                'WYPŁATA',
                'Tel./@  br/kl',
                'Tel. TU',
                'Monit',
                'Status',
                'Informacja dodatkowa',
                'Wycena',
                'Przekazano do wyliczenia',
                'Ugoda',
              ]"
            >
              <template v-slot:option="option">
                {{option.label}}
              </template>
              <template v-slot:selected-option="option">
                {{option.label}}
              </template>
            </v-select>

            <b-form-invalid-feedback :state="getValidationState(validationProps)">
              {{ validationProps.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider

          #default="validationProps"
          :name="$t('label_description')"
          rules="required"
          slim
        >
          <b-form-group
            :label="$t('label_description')"
          >
            <quill-editor
              id="n-description"
              ref="editor"
              placeholder=""
              v-model="itemData.content"
              class="product"
              :options="editorOption"
              :state="getValidationState(validationProps)"
            >
              <div
                id="toolbar"
                slot="toolbar"
              >
                <!-- Add a bold button -->
                <span class="ql-formats">
                  <button class="ql-clean" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                </span>
                <span class="ql-formats">
                  <select class="ql-align" />
                  <select class="ql-color" />
                </span>
                <span class="ql-formats">
                  <select class="ql-size" />
                  <select class="ql-font" />
                </span>
              </div>
            </quill-editor>

            <b-form-invalid-feedback :state="getValidationState(validationProps)">
              {{ validationProps.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <label
            for="share_to_role"
            class="w-100"
        >
          <b-form-checkbox
              v-model="selectedAll"
              inline
              name="event-filter-all"
              class="mb-1"
              :class="`custom-control-primary`"
              @change="selectAllRoles"
          >
            {{ $t('label_share_to_role') }}
          </b-form-checkbox>
        </label>
        <b-form-checkbox-group
          id="share_to_role"
          v-model="itemData.share_to_role"
          name="share_to_role"
          stacked
          :label="$t('label_share_to_role')"
        >
          <b-form-checkbox
            v-for="item in roleItems"
            :key="item.role"
            :disabled="item.disabled"
            name="event-filter"
            :value="getRole(item.role)"
            class="mb-1 col-sm-6"
            :class="`custom-control-primary`"
          >
            {{ $t('label_' + item.role) }}
          </b-form-checkbox>
        </b-form-checkbox-group>

        <div class="d-flex justify-content-end mt-2">
          <button
            type="reset"
            class=" btn btn-default"
            @click.prevent="$bvModal.hide(PREFIX + '-modal')"
          >
            {{ $t('label_close') }}
          </button>

          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light"
            @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
          >{{ (action ==
            'editing')?$t('label_save'):$t('label_add') }}
          </button>
        </div>

      </validation-observer>

    </template>

  </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
      BFormTextarea,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BInputGroupPrepend, BFormCheckboxGroup,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import { quillEditor } from 'vue-quill-editor'
    import { NOTE_PREFIX as PREFIX, AGREEMENT_PREFIX, DEAL_PREFIX} from "../moduleHelper"

    export default {
        components: {
          vSelect,
          quillEditor,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            // vSelect
            BFormCheckboxGroup,
        },
        props: ['moduleItem', 'editedItem', 'isDeal'],
        data() {
            return {
              userData: JSON.parse(localStorage.getItem('userData')),
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX: '',
                PREFIX,
              editorOption: {
                modules: {
                  toolbar: '#toolbar',
                },
              },
                action: 'adding',

                blankItemData: {
                    title: '',
                    content: '',
                    // deadline_at: '',
                    agreement_id:'',
                    share_to_role: [],
                },

                itemData: {},
                roleItems: [],
                selectedAll: false,
            }
        },
      computed: {
        defaultRoles() {
          return this.roleItems.map(role => (role.default ? this.getRole(role.role, true) : '')).filter(Boolean)
        },
        disabledRoles() {
          return this.roleItems.map(role => (!role.disabled ? this.getRole(role.role, true) : '')).filter(Boolean)
        },
      },
        watch: {
          'itemData.share_to_role': {
            deep: true,
            handler(newValue) {
              if ((this.disabledRoles.length + this.defaultRoles.length) === newValue?.length) this.selectedAll = true
              else this.selectedAll = false
            },
          },
            moduleItem: function (moduleItem) {
                if (moduleItem) {
                    this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
                    this.blankItemData.agreement_id = this.isDeal ? moduleItem.agreement_id : moduleItem.id;
                }
            }
        },
        async mounted() {
          await this.getPageRoles()
        },
        methods: {
          selectAllRoles(value) {
            if (value) this.itemData.share_to_role = [...this.disabledRoles, ...this.defaultRoles]
            else this.itemData.share_to_role = [...this.defaultRoles]
          },
            initData() {

                if (this.editedItem) {
                  this.action = 'editing';
                  let item = { ...this.blankItemData};
                  item.id = this.editedItem.id;
                  for (let prop in item) {
                      if (this.editedItem.hasOwnProperty(prop)) {
                          item[prop] = this.editedItem[prop];
                      }
                  }
                  item.share_to_role = (typeof item.share_to_role === 'string' ? item.share_to_role?.split(',').filter(Boolean) : item.share_to_role) ?? []

                  this.itemData = item;
                } else {
                  this.action = 'adding';
                  this.itemData = { ...this.blankItemData };
                  this.itemData.share_to_role = this.roleItems.map(role => (role.default ? this.getRole(role.role, true) : '')).filter(Boolean)
                }
            },
            resetForm() {
                this.itemData = { ...this.blankItemData};
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            async getPageRoles(){
              this.roleItems = this.getSystemRoles(this.userData?.role)
            },
            onSubmit() {
              console.log('submit')
                if (this.action == 'adding') {
                    this.async('post', '/agreement_' + this.PREFIX + 's', this.itemData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                } else {
                    this.async('put', '/agreement_' + this.PREFIX + 's/' + this.itemData.id, this.itemData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                }

            }
        },
    }
</script>
